import { db, storage } from '../firebase'
import {
  doc,
  getDocs,
  getDoc,
  collection,
  setDoc,
  updateDoc,
  onSnapshot,
  deleteDoc
} from 'firebase/firestore'
import { getAuth, Unsubscribe } from 'firebase/auth'
import { Game } from '@/models/Game'
import { Room } from '@/models/Room'
import { Puzzle } from '@/models/Puzzle'
import { Clue, ClueType } from '@/models/Clue'
import { Ref } from 'vue'
import {
  getDownloadURL,
  ref as fbStorageRef,
  uploadBytes,
  deleteObject
} from 'firebase/storage'
// import store from '@/store'

class Guid {
  static newGuid () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0
        const v = c === 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      }
    )
  }
}

class RoomService {
  public async get (clientId: string, roomId: string): Promise<Room> {
    const auth = getAuth()
    if (auth != null && auth.currentUser != null) {
      const roomRef = await doc(db, 'clients', clientId, 'rooms', roomId)
      const room = await getDoc(roomRef)
      // console.log(room.data())
      if (room.exists()) {
        const newRoom = new Room(clientId, room.data().name, 50)
        // newRoom.setTimeLimit()
        const puzzles = room.data().puzzles

        puzzles.forEach((puzzle: Puzzle) => {
          const newPuzzle = new Puzzle(puzzle.name)

          const clues = puzzle.clues

          console.log('clues', clues)
          clues.forEach((clue: Clue) => {
            const newClue = new Clue(
              clue.name,
              clue.text,
              clue.source,
              ClueType[clue.type as keyof typeof ClueType]
            )

            console.log(newClue)

            newPuzzle.addClue(newClue)
          })
          newRoom.addPuzzle(newPuzzle)
        })
        // console.log('new room: ', newRoom)

        return newRoom

        // return rooms
      } else {
        // doc.data() will be undefined in this case
        throw new Error()
        console.log('No such document!')
      }
    } else {
      throw new Error()
    }
  }

  async getClients (auth?: any) {
    // /databases/users/$(uid)/clients/documents
    const clients: any[] = []
    // const auth = getAuth()
    if (auth?.currentUser != null) {
      const docref = await collection(
        db,
        `users/${auth.currentUser.uid}/clients`
      )
      const querySnap = await getDocs(docref)

      querySnap.forEach((doc) => {
        clients.push({ id: doc.id, name: doc.data().name || '', rooms: [] })
      })
    }

    return clients
  }

  async getRooms (client: string) : Promise<Room[]> {
    // /databases/users/$(uid)/clients/documents
    const rooms: Room[] = []
    const auth = getAuth()
    if (auth?.currentUser != null) {
      // console.log(auth.currentUser.uid)
      const docref = await collection(db, `clients/${client}/rooms`)
      const querySnap = await getDocs(docref)

      querySnap.forEach((doc) => {
        rooms.push(new Room(client, doc.data().name, 60))
      })
    }
    // console.log(rooms)

    return rooms
  }

  // async getRooms () {}

  async create (clientId: string, roomId: string) {
    const auth = getAuth()
    if (auth != null && auth.currentUser != null) {
      await setDoc(doc(db, 'clients', clientId, 'rooms', roomId), {
        name: roomId,
        timeLimit: 60,
        puzzles: []
      })
    }
  }

  async updatePuzzles (room: Room) {
    const roomRef = doc(db, 'clients', room.clientId, 'rooms', room.name)

    const newPuzzles: Puzzle[] = []

    room.puzzles.forEach((puzzle) => {
      const p = new Puzzle(puzzle.name)

      const clues: Clue[] = []
      puzzle.clues.forEach((clue) => {
        clues.push(
          new Clue(
            clue.name,
            clue.text,
            clue.source,
            ClueType[clue.type as keyof typeof ClueType]
          )
        )
      })
      p.clues = clues
      newPuzzles.push(p)
    })

    await updateDoc(roomRef, {
      puzzles: newPuzzles
    })
  }

  update () {
    console.log('Not implemented')
  }

  public async delete (client: any, room: any) {
    await deleteDoc(doc(db, 'clients', client.id, 'rooms', room.name))
    return client.rooms.filter((x: { name: any }) => x.name !== room.name)
  }

  deleteAll () {
    console.log('Not implemented')
  }

  public syncRoom (room: Ref<Room | undefined>, route: any): Unsubscribe {
    const clientId = route.params.client as string
    const roomId = route.params.room as string

    const unsubscribe = onSnapshot(
      doc(db, 'clients', clientId, 'rooms', roomId),
      async (doc) => {
        const data = doc.data()
        if (data) {
          const newRoom = new Room(clientId, data.name, data.timeLimit)
          data.puzzles.forEach((puzzle: Puzzle) => {
            const newPuzzle = new Puzzle(puzzle.name)
            puzzle.clues.forEach((clue: Clue) => {
              const newClue = new Clue(
                clue.name,
                clue.text,
                clue.source,
                ClueType[clue.type as keyof typeof ClueType]
              )
              newClue.used = clue.used
              newPuzzle.addClue(newClue)
            })
            newRoom.addPuzzle(newPuzzle)
          })
          newRoom.timeRemainingDialog = data.timeRemainingDialog || {}
          newRoom.clueIconDialog = data.clueIconDialog || {}
          newRoom.clueTransmissionDialog = data.clueTransmissionDialog || {}
          newRoom.backgroundImage = data.backgroundImage || ''
          newRoom.clues = data.clues || []
          console.log('Clues!', data.clues ? 'yes' : 'no')
          newRoom.clueLimit = data.clueLimit || 5
          // store.commit('setRoom', newRoom)
          room.value = newRoom
        }
      }
    )
    return unsubscribe
  }

  public async saveRoom (room: Ref<Room | undefined>, route: any) {
    const clientId = route.params.client as string
    const roomId = route.params.room as string
    await setDoc(
      doc(db, 'clients', clientId, 'rooms', roomId),
      room.value?.toJSON()
      // store.state.room.toJSON()
    )
  }

  public syncGame (game: Ref<Game | undefined>, route: any, store: any, cb: any): Unsubscribe {
    const clientId = route.params.client as string
    const roomId = route.params.room as string

    const unsubscribe = onSnapshot(
      doc(db, 'clients', clientId, 'games', roomId),
      (doc) => {
        const data = doc.data()
        // console.log('Data from FB:', data)
        game.value = new Game(clientId, roomId)
        if (data) {
          game.value.syncData(data)
        }
        store.commit('setGame', game.value)
        if (cb) { cb(game.value.commands) }
      }
    )
    return unsubscribe
  }

  public async saveGame (game: Ref<Game | undefined>, route: any) {
    const clientId = route.params.client as string
    const roomId = route.params.room as string
    await setDoc(
      doc(db, 'clients', clientId, 'games', roomId),
      game.value?.toJSON()
    )
  }

  public async uploadBackgroundImage (room: Ref<Room | undefined>, file: File) {
    const storageRef = fbStorageRef(
      storage,
      `${room.value?.clientId}/${room.value?.name}/${Guid.newGuid()}`
    )

    const metadata = {
      contentType: 'image/jpeg',
      clientId: room.value?.clientId,
      roomId: room.value?.name
    }

    let downloadURL = ''

    // 'file' comes from the Blob or File API
    await uploadBytes(storageRef, file, metadata).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {
        downloadURL = url
      })
    })

    return downloadURL
  }

  public async uploadClueIcon (room: Ref<Room | undefined>, file: File) {
    const storageRef = fbStorageRef(
      storage,
      `${room.value?.clientId}/${room.value?.name}/${Guid.newGuid()}`
    )

    const metadata = {
      contentType: 'image/jpeg',
      clientId: room.value?.clientId,
      roomId: room.value?.name
    }

    let downloadURL = ''

    // 'file' comes from the Blob or File API
    await uploadBytes(storageRef, file, metadata).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {
        downloadURL = url
      })
    })

    return downloadURL
  }

  public async uploadClue (room: Ref<Room | undefined>, clue: Clue, file: File) {
    const storageRef = fbStorageRef(
      storage,
      `${room.value?.clientId}/${room.value?.name}/${Guid.newGuid()}`
    )

    const metadata = {
      contentType:
        clue.type === ClueType.Image
          ? 'image/jpeg'
          : clue.type === ClueType.Sound
            ? 'audio/mpeg'
            : clue.type === ClueType.Video
              ? 'video/mp4'
              : 'file',
      clientId: room.value?.clientId,
      roomId: room.value?.name
    }

    let downloadURL = ''

    // 'file' comes from the Blob or File API
    await uploadBytes(storageRef, file, metadata).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {
        downloadURL = url
      })
    })

    return downloadURL
  }

  public async uploadFile (room: Ref<Room | undefined>, file: File) {
    const storageRef = fbStorageRef(
      storage,
      `${room.value?.clientId}/${room.value?.name}/${Guid.newGuid()}`
    )

    const metadata = {
      contentType: 'image',
      clientId: room.value?.clientId,
      roomId: room.value?.name
    }

    let downloadURL = ''

    // 'file' comes from the Blob or File API
    await uploadBytes(storageRef, file, metadata).then(async (snapshot) => {
      await getDownloadURL(snapshot.ref).then((url) => {
        downloadURL = url
      })
    })

    return downloadURL
  }

  public async deleteFile (room: Ref<Room | undefined>, fileUrl: string) {
    const storageRef = fbStorageRef(
      storage,
      fileUrl
    )
    await deleteObject(storageRef)
  }
}

export default new RoomService()
