export enum ClueType {
  Image = 'Image',
  Text = 'Text',
  Sound = 'Sound',
  Video = 'Video',
  New = 'New'
}
// Sounds: background, beforeStart, afterStart, success, fail, loop?
// Image: background, beforeStart, success, fail
// Video: beforeStart, success, fail
export function getClueTypes (): any[] {
  const clueTypes: any[] = []
  Object.values(ClueType).forEach((clueType) => {
    if (clueType !== 'New' && isNaN(parseInt(clueType.toString()))) {
      clueTypes.push({ label: clueType, value: clueType })
    }
  })
  return clueTypes
}

export enum CluePosition {
  First,
  Last,
  Middle,
  Alone
}

export class Clue {
  name: string
  text: string
  source: string
  type: ClueType
  used: boolean

  constructor (name: string, text: string, source: string, type: ClueType) {
    this.name = name
    this.text = text
    this.source = source
    this.type = type
    this.used = false
  }

  toJSON (): any {
    return {
      name: this.name,
      text: this.text,
      source: this.source,
      type: this.type,
      used: this.used
    }
  }
}
