// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import { getDatabase } from 'firebase/database'
// TODO: Add SDKs for Firebase products that you want to use
import { getFirestore, collection } from 'firebase/firestore'
// https://firebase.google.com/docs/web/setup#available-libraries
import { getStorage } from 'firebase/storage'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: 'AIzaSyCBUHadJ5qCFB0CbuI2tX1fwhmX9xw2fUc',
  authDomain: 'escapeit-runner-dev.firebaseapp.com',
  projectId: 'escapeit-runner-dev',
  storageBucket: 'escapeit-runner-dev.appspot.com',
  messagingSenderId: '230429345727',
  appId: '1:230429345727:web:6d58b47218298229e2030a'
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const db = getFirestore(app)
const storage = getStorage(app)

export { db, storage }
