import { Clue } from './Clue'
export class Puzzle {
  name: string
  collapsed = false
  clues: Clue[]

  constructor (name: string) {
    this.name = name
    this.clues = []
  }

  removeClue (index: number): boolean {
    this.clues.splice(index)
    return true
  }

  addClue (clue: Clue): boolean {
    this.clues.push(clue)
    return true
  }

  toJSON (): any {
    const clues: any = []
    this.clues.forEach((clue) => {
      clues.push(clue.toJSON())
    })
    return {
      name: this.name,
      clues
    }
  }
}
